<template>
  <div>
    <!-- 动态生成筛选项 -->
    <div class="dynamicFiltering top">
      <!-- 关键词搜索 -->
      <nav class="strSearch">
        <div class="topCon">
          <span class="keyword" v-if="!$parent.isImgSearch">{{ $fanyi("关键词") }}：{{
            $route.query.CN ? $route.query.keyword : $parent.keyWord
          }}
          </span>
          <div class="ImgBox" v-else>

            <el-popover placement="right" trigger="manual" :width="280" v-model="mainImageAreavisible">
              <div class="ImageSelectorBox">
                <ImageSelector :imageSrc="imgUrl" @selection="handselection" :initialSelection="newinitialSelection"
                  :containerSize="252" />
                <div class="footer">
                  <!-- 取消 -->

                  <el-button @click="mainImageAreavisible = false">{{
                    $fanyi('取消')
                  }}</el-button>
                  <!-- 确认 -->
                  <el-button @click="ImageAreaconfirm" type="primary">{{
                    $fanyi('确认')
                  }}</el-button>
                </div>
              </div>
              <template slot="reference">
                <div class="mainImageArea">
                  <ImageSelector :imageSrc="imgUrl" :initialSelection="initialSelection" :containerSize="90"
                    :draggable="false" :showCorners="false" />
                  <div class="boxSelectionSubject">
                    <img @click.stop="selectionSubjectClick" src="../../../assets/hometop/box.svg" alt="" />
                  </div>
                </div>
              </template>
            </el-popover>

            <div class="imgsList">
              <div class="img" @click="imgClick(imgItem)" :class="{ imgactive: imgItem.activeChecked == true }"
                v-for="(imgItem, index) in imageRecognitionlist">
                <el-image :src="imgItem.url" />
              </div>
            </div>
          </div>

          <!-- <span class="quantity"
            >{{ $fanyi("共")
            }}<font>
              {{
                $parent.shopType == "1688"
                  ? $parent.total1688
                  : $parent.totalTaobao
              }} </font
            >{{ $fanyi("个产品") }}</span
          > -->
          <div class="crosswise">
            <div @click="$parent.Crosswise = true">
              <img v-if="!$parent.Crosswise" :src="require('@/assets/SearchResultsPage/vertical_1.png')" alt="" />
              <img v-else :src="require('@/assets/SearchResultsPage/vertical_0.png')" alt="" />
            </div>
            <div @click="$parent.Crosswise = false">
              <img v-if="$parent.Crosswise" :src="require('@/assets/SearchResultsPage/transverse_0.png')" alt="" />
              <img v-else :src="require('@/assets/SearchResultsPage/transverse_1.png')" alt="" />
            </div>
          </div>
        </div>
      </nav>

      <div v-if="false">
        <div class="row" v-for="(rowItem, rowIndex) in keySearchList" :key="rowIndex">
          <label for="">{{ rowItem.translateName }}：</label>
          <div class="spanBox" v-for="(valItem, valIndex) in rowItem.children" :key="valIndex" :class="{
            active: find_owned_snId(rowItem.id, valItem.id),
          }" @click="chiose_snId(rowItem, valItem)">
            {{ valItem.translateName }}
          </div>
        </div>
      </div>
    </div>
    <!-- 固定筛选项 -->
    <div class="dynamicFiltering">
      <div class="row">
        <label for="">{{ $fanyi("显赫") }} ：</label>
        <el-checkbox v-model="valueConTrol.yanXuanShangPin1688" @change="confirmChuanshen">{{ $fanyi("1688严选商品")
          }}</el-checkbox>
        <el-checkbox v-model="valueConTrol.yanXuanGongChang1688" @change="confirmChuanshen">{{ $fanyi("1688严选工厂")
          }}</el-checkbox>
        <div class="selectBox dipflex flexacenter">
          <div class="selectName">{{ $fanyi("店铺评分") }}:</div>
          <el-select v-model="valueConTrol.dianPuPinFen" placeholder="" @change="confirmChuanshen">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="switchBox dipflex flexacenter" style="margin-left:auto">
          <img :src="require('@/assets/allnewFile/cicon.svg')" alt="" />
          <img :src="require('@/assets/allnewFile/eicon.svg')" alt="" />
          <img :src="require('@/assets/allnewFile/maohao.svg')" alt="" style="margin-right:0" />
          <el-switch v-model="valueConTrol.ce" active-color="#1767D0" @change="confirmChuanshen"
            :active-text="$fanyi('与证书')" :inactive-text="$fanyi('查看')">
          </el-switch>
        </div>
      </div>
      <div class="line"></div>
      <div class="row">
        <label for="">{{ $fanyi("条件") }} ：</label>
        <el-checkbox v-model="valueConTrol.qiTianWuLiYou" @change="confirmChuanshen">{{ $fanyi("七天无理由退换")
          }}</el-checkbox>
        <el-checkbox v-model="valueConTrol.yiJinaQiFa" @change="confirmChuanshen">{{ $fanyi("一件起发") }}</el-checkbox>
      </div>

      <div class="line"></div>
      <div class="row">
        <div class="sortingOptions" style="margin-right:unset;" :class="{ active: zonghe }" @click="setSortValue()">
          <label>Ordenar por ：</label>
        </div>
        <div class="sortingOptions" :class="{ active: haveSort('monthSold') }" @click="setSortValue('monthSold')">
          <span>{{ $fanyi("月销售总额") }}</span>
          <div class="sortIcon " :class="{ down: haveSort('monthSold') }"></div>
        </div>
        <div class="sortingOptions" :class="{ active: haveSort('rePurchaseRate') }"
          @click="setSortValue('rePurchaseRate')">
          <span>{{ $fanyi("回购率") }}</span>
          <div class="sortIcon " :class="{ down: haveSort('rePurchaseRate') }"></div>
        </div>
        <div class="sortingOptions" :class="{ active: haveSort('price') }" @click="setSortValue('price')">
          <span>{{ $fanyi("单价排序") }}</span>
          <div class="sortIcon" :class="{ up: priceSort() == 'desc', down: priceSort() == 'asc' }"></div>
        </div>
      </div>
      <div class="line"></div>
      <div class="row">
        <label>{{ $fanyi("价格范围") }} ：</label>

        <div class="priceRangeBox flex acenter">
          <span>{{ $fanyi("最低价格") }}</span>

          <div class="inputbox">
            <div class="shadow" v-if="!valueConTrol.priceStart">
              €<img src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/668b984f4333f.svg" alt="" />
            </div>
            <el-input clearable v-model="valueConTrol.priceStart"></el-input>
          </div>

          <span class="segmentation">-</span>
          <div class="inputbox">
            <div class="shadow" v-if="!valueConTrol.priceEnd">
              €<img src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/668f9c97de1e3.svg" alt="" />
            </div>
            <el-input clearable v-model="valueConTrol.priceEnd"></el-input>
          </div>
          <span>{{ $fanyi("最高价格") }}</span>
        </div>
        <div class="searchBtn">
          <el-button class="clearBtn" @click="clearChiose">
            {{ $fanyi("清理") }}
          </el-button>
          <el-button class="bgBlueBtn" @click="confirmChuanshen">{{
            $fanyi("确认")
          }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageSelector from '@/components/ImageSelector/index.vue'
export default {
  data() {
    return {
      valueConTrol: {
        yanXuanShangPin1688: false, //1688严选商品
        yanXuanGongChang1688: false, //1688严选工厂
        dianPuPinFen: "", //店铺评分
        qiTianWuLiYou: false, //七天无理由
        yiJinaQiFa: false, //一件起发
        sort: "", //排序字符串
        sortObj: [],
        priceStart: "", //起始价格
        priceEnd: "", //结束价格
        snId: {}, //动态搜索项
        ce: true, //ce筛选
      },
      imgUrl: '',// 图片
      // 分割图片数据
      region: '', // 坐标
      initialSelection: null, //默认主图切割坐标
      newinitialSelection: null, // 弹层主图坐标
      mainImageAreavisible: false, // 是否展示弹层
      imageRecognitionlist: [], // 图片切割数组
      zonghe: false,
      options: [
        {
          value: "",
          label: "Todo",
        },
        {
          value: "totalEpScoreLv1",
          label: "5★",
        },
        {
          value: "totalEpScoreLv2",
          label: "4.5★~5.0★",
        },
        {
          value: "totalEpScoreLv3",
          label: "4.0★~4.5★",
        },
      ],
      keySearchList: [],
    };
  },
  components: {
    ImageSelector
  },
  computed: {},
  created() {
    // this.getDynamicFilteringParameters();
    this.confirmChuanshen();
  },
  methods: {
    cechange(v) {
      this.valueConTrol.ce = [v];
      this.confirmChuanshen();
    },

    // 重置/清理
    clearChiose() {
      this.valueConTrol = {
        yanXuanShangPin1688: false, //1688严选商品
        yanXuanGongChang1688: false, //1688严选工厂
        dianPuPinFen: "", //店铺评分
        qiTianWuLiYou: false, //七天无理由
        yiJinaQiFa: false, //一件起发
        sort: "", //排序字符串
        sortObj: [],
        priceStart: "", //起始价格
        priceEnd: "", //结束价格
        snId: {}, //动态搜索项
        ce: true,
      };
      this.confirmChuanshen();
    },

    // 获取动态筛选参数
    getDynamicFilteringParameters() {
      this.$api
        .keywordSNQuery({
          keyword: this.$route.query.keyword,
        })
        .then((res) => {
          ////console.log('事件名',res)
          if (res.code != 0)
            return this.$message.error(this.$fanyi(res.data.msg));

          //接下来的操作
          res.data.forEach((data) => {
            data.children.forEach((element) => {
              let id = element.id;
              id = id.split(":");
              element.id = id[1];
            });
          });

          this.keySearchList = res.data;
        });
    },
    // 图标展示判断
    priceSort() {
      if (this.valueConTrol.sort.indexOf("price") != -1) {
        if (this.valueConTrol.sort.indexOf("desc") != -1) {
          return "desc";
        } else {
          return "asc";
        }
      } else {
        return "";
      }
    },

    // 设置sort参数
    setSortValue(name) {
      if (!name) {
        this.valueConTrol.sort = "";
        this.zonghe = true;
      } else if (name == "price") {
        this.zonghe = false;
        if (this.valueConTrol.sort.indexOf("desc") != -1) {
          this.valueConTrol.sort = `{${name}:'asc'}`;
          this.valueConTrol.sortObj = [
            {
              key: name,
              value: "asc",
            },
          ];
        } else {
          this.valueConTrol.sort = `{${name}:'desc'}`;
          this.valueConTrol.sortObj = [
            {
              key: name,
              value: "desc",
            },
          ];
        }
      } else {
        this.zonghe = false;
        this.valueConTrol.sort = `{${name}:'desc'}`;
        this.valueConTrol.sortObj = [
          {
            key: name,
            value: "asc",
          },
        ];
      }
      this.confirmChuanshen();
      this.$forceUpdate();
    },
    // 点击确认
    ImageAreaconfirm() {
      if (this.newinitialSelection) {
        const { left, right, top, bottom } = this.newinitialSelection
        this.region = `${Number(left).toFixed(0)},${Number(right).toFixed(
          0
        )},${Number(top).toFixed(0)},${Number(bottom).toFixed(0)}`
      } else if (this.initialSelection) {
        const { left, right, top, bottom } = this.initialSelection
        this.region = `${Number(left).toFixed(0)},${Number(right).toFixed(
          0
        )},${Number(top).toFixed(0)},${Number(bottom).toFixed(0)}`
      }
      this.mainImageAreavisible = false

      this.confirmChuanshen(true)
    },

    handselection(val) {
      this.newinitialSelection = val
    },
    // 点击框选主体
    selectionSubjectClick() {
      this.mainImageAreavisible = true
      if (!this.newinitialSelection) {
        this.newinitialSelection = this.initialSelection
      }
    },
    // 判断sort是否有已选
    haveSort(name) {
      if (this.valueConTrol.sort.indexOf(name) != -1) {
        return true;
      } else {
        return false;
      }
    },

    // 拼凑传参
    confirmChuanshen(Isnewregion) {
      let searchData = {};
      if (this.$route.query.type == "imgSearch") {
        // console.log(JSON.parse(this.valueConTrol.sort));
        searchData.order_by = this.valueConTrol.sortObj;
        searchData.priceMin = this.valueConTrol.priceStart;
        searchData.priceMax = this.valueConTrol.priceEnd;
        searchData.region = this.region
        this.imgUrl = this.$route.query.imgUrl;
        let filter = [];
        if (this.valueConTrol.yanXuanShangPin1688) {
          filter.push("jxhy");
        }
        if (this.valueConTrol.yanXuanGongChang1688) {
          filter.push("certifiedFactory");
        }
        if (this.valueConTrol.dianPuPinFen) {
          filter.push(this.valueConTrol.dianPuPinFen);
        }
        if (this.valueConTrol.qiTianWuLiYou) {
          filter.push("noReason7DReturn");
        }
        if (this.valueConTrol.yiJinaQiFa) {
          filter.push("isOnePsale");
        }
      } else {
        let filter = [];
        if (this.valueConTrol.yanXuanShangPin1688) {
          filter.push("jxhy");
        }
        if (this.valueConTrol.yanXuanGongChang1688) {
          filter.push("certifiedFactory");
        }
        if (this.valueConTrol.dianPuPinFen) {
          filter.push(this.valueConTrol.dianPuPinFen);
        }
        if (this.valueConTrol.qiTianWuLiYou) {
          filter.push("noReason7DReturn");
        }
        if (this.valueConTrol.yiJinaQiFa) {
          filter.push("isOnePsale");
        }

        let snidStr = [];
        Object.keys(this.valueConTrol.snId).forEach((key) => {
          if (this.valueConTrol.snId[key]) {
            snidStr.push(key + ":" + this.valueConTrol.snId[key]);
          }
        });
        snidStr = snidStr.join(";");
        searchData = {
          filter: filter.join(","),
          sort: this.valueConTrol.sort,
          priceStart: this.valueConTrol.priceStart,
          priceEnd: this.valueConTrol.priceEnd,
          snId: snidStr,
          productCollectionId: Number(this.valueConTrol.ce),

        };

      }
      console.log(Isnewregion);

      this.$emit("filterData", searchData, Isnewregion);
    },
    getImageSliceList(region, Isnewregion) {
      if (Isnewregion) {
        this.imageRecognitionlist = []
      } else {
        if (this.imageRecognitionlist.length > 0) {
          return
        }
      }
      // 假设 res.data.result 是一个数组，包含每个区域的坐标
      const results = region // [{ left, right, top, bottom }, ...]

      // 创建图片对象
      const img = new Image()
      img.src = this.imgUrl ? this.imgUrl : this.$route.query.imgUrl // 设置图片源为 Base64
      img.crossOrigin = 'Anonymous'
      img.onload = () => {
        const croppedImages = [] // 存储切割结果

        results.forEach((resultsItem, index) => {
          for (let i in resultsItem.location) {
            resultsItem.location[i] = Number(resultsItem.location[i])
          }
          const { left, right, top, bottom } = resultsItem.location // 获取切割区域
          let width = right - left
          let height = bottom - top
          // // 验证切割区域是否合法

          const croppedCanvas = document.createElement('canvas')
          croppedCanvas.width = width
          croppedCanvas.height = height
          const croppedCtx = croppedCanvas.getContext('2d')

          // 切割指定区域
          croppedCtx.drawImage(
            img,
            left,
            top, // 原图起点
            width,
            height, // 原图宽高
            0,
            0, // 目标起点
            width,
            height // 目标宽高
          )
          // console.log(img, croppedCtx);
          // 转为 Base64 并保存到数组
          const dataURL = croppedCanvas.toDataURL('image/png')
          if (dataURL === 'data:,') {
            console.error(`区域 ${index + 1} 生成的图片为空`)
          } else {
            // 最多八张
            if (croppedImages.length >= 8) {
              return
            }
            croppedImages.push({
              url: dataURL,
              region: `${left},${right},${top},${bottom}`,
              activeChecked: index == 0 ? true : false,
              location: resultsItem.location,
            })
          }
        }) // 示例：打印所有切割后的图片
        this.imageRecognitionlist = croppedImages
      }

      img.onerror = (err) => {
        console.error('图片加载失败:', err)
        this.$message.error(this.$fanyi('图片加载失败'))
      }
    },
    imgClick(row) {
      this.imageRecognitionlist.forEach((item) => {
        item.activeChecked = false
      })
      row.activeChecked = true
      this.initialSelection = row.location
      this.region = row.region
      this.confirmChuanshen()
    },
    find_owned_snId(name, v) {
      // console.log(this.valueConTrol.snId);
      if (
        this.valueConTrol.snId[name] != undefined &&
        this.valueConTrol.snId[name] == v
      ) {
        return true;
      } else {
        return false;
      }
    },
    chiose_snId(rowItem, valItem) {
      if (
        this.valueConTrol.snId[rowItem.id] &&
        this.valueConTrol.snId[rowItem.id] == valItem.id
      ) {
        this.valueConTrol.snId[rowItem.id] = "";
      } else {
        this.valueConTrol.snId[rowItem.id] = valItem.id;
      }
      this.$forceUpdate();
      this.confirmChuanshen();
      // console.log(this.valueConTrol.snId);
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.ImageSelectorBox {
  .footer {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;

    /deep/.el-button {
      border-radius: 8px;
      height: 32px;
      display: flex;
      align-items: center;

      &:first-child {
        background-color: #fff;
        font-weight: 400;
        font-size: 14px;
        color: #1A73E8;

        border: 1px solid #77b2ff;
      }

      &:last-child {
        background: #1A73E8;
        border-radius: 8px;
        margin-left: 20px;
        border: 1px solid #1A73E8;
      }
    }
  }
}

.dynamicFiltering {
  box-shadow: 0px 0px 10px 0px rgba(61, 61, 61, 0.1);
  background-color: white;
  margin: 0 auto 20px;
  width: 1400px;
  padding: 20px 32px;
  border-radius: 10px;

  .row {
    height: 36px;
    display: flex;
    align-items: center;
    gap: 24px;

    &:last-child {
      border-bottom: none;
    }

    label {
      font-size: 14px;
      font-weight: 600;
      color: #475467;
    }

    .cetitle {
      @extend .dip;

      img {
        margin-right: 6px;
      }
    }

    .spanBox {
      margin-right: 20px;
      padding: 3px 10px;
      border-radius: 4px;
      cursor: pointer;
    }

    .spanBox.active {
      background-color: #1e2997;
      color: white;
    }

    .el-checkbox {
      margin-right: 0px;

      /deep/.el-checkbox__label {
        font-weight: 400;
        font-size: 14px;
      }
    }

    .el-checkbox.is-checked {
      /deep/.el-checkbox__inner {
        background-color: #1767d0;
        border-color: #1767d0;
      }

      /deep/.el-checkbox__label {
        color: #1767d0;
      }
    }

    .sortingOptions {
      color: #475467;
      cursor: pointer;
      display: flex;

      label {
        font-weight: 600;
        font-size: 14px;
      }

      span {
        margin-right: 5px;
        font-size: 14px;
      }

      .sortIcon {
        width: 24px;
        height: 24px;
        background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6684f47b49488.svg");
        background-size: 100% 100%;
      }

      .down {
        background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6684fe7856e26.png");
        transform: rotate(180deg);
        /* 旋转90度 */
      }

      .up {
        background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6684fe7856e26.png");
      }
    }

    .sortingOptions.active {
      color: #1767d0;
    }

    .priceRangeBox {
      margin-right: 24px;

      .inputbox {
        position: relative;

        .el-input {
          width: 60px;

          /deep/.el-input__inner {
            height: 36px;
            background-color: transparent;
            padding: 0 10px;
          }

          /deep/.el-input__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .shadow {
          position: absolute;
          width: 60px;
          height: 36px;
          color: var(--Gray-300, #d0d5dd);
          font-size: 18px;
          font-weight: 600;
          @extend .dip;
        }
      }

      span {
        font-size: 14px;
        color: #475467;

        &:first-child {
          margin-right: 8px;
        }

        &:last-child {
          margin-left: 8px;
        }
      }

      .segmentation {
        margin: 5px;
      }
    }

    .searchBtn {
      margin-left: auto;

      .el-button {
        height: 44px;
        padding: 0 12px;
        font-size: 14px;
        font-weight: 700;
      }

      .clearBtn {
        color: var(--Rakumart-700, #1355ab);
        width: 100px;
        margin-right: 24px;
        border: none;
      }

      .bgBlueBtn {
        margin-left: 0;
        width: 180px;
      }
    }

    .selectBox {
      .selectName {
        font-size: 14px;
        margin-right: 16px;
        color: #475467;
      }

      .el-select {
        /deep/.el-input__inner {
          border-radius: 8px;
          width: 111px;
          height: 36px;
        }

        /deep/.el-input__suffix {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .switchBox {
      img {
        margin-right: 6px;
      }

      .el-switch {
        /deep/.el-switch__label {
          font-size: 14px;
          color: #98a2b3;
        }

        /deep/.el-switch__label.is-active {
          color: #1767d0;
        }

        /deep/.el-switch__label--left {
          margin-right: 8px;
        }

        /deep/.el-switch__label--right {
          margin-right: 8px;
        }
      }
    }
  }

  .line {
    border-top: 1px dashed #dfdfdf;
    margin: 12px 0;
  }
}

.dynamicFiltering.top {
  padding: 0 32px;
}

.el-checkbox {
  /deep/.el-checkbox__original {
    display: none;
  }
}

nav {
  min-height: 80px;

  // border-bottom: dashed 1px #dfdfdf;
  // 切换显示商品形式
  .crosswise {
    display: flex;
    margin-left: auto;
    cursor: pointer;

    >div {
      height: 40px;

      &:first-child {
        margin-right: 20px;
      }
    }

    img {
      $searchImgSize: 40px;
      width: $searchImgSize;
      height: $searchImgSize;
    }
  }
}

nav.strSearch {
  min-height: 80px;
  // background-color: white;
  // box-shadow: 0px 0px 10px 0px rgba(61, 61, 61, 0.1);
  // border-radius: 4px;
  // padding: 0 20px 20px 20px;
  // width: $pageWidth;
  // margin: 30px auto 30px;
  position: relative;
  left: 0px;

  >div {
    display: flex;
  }

  .topCon {
    padding: 20px 0 20px;
    display: flex;
    align-items: center;


    .ImgBox {
      display: flex;

      .mainImageArea {
        width: 90px;
        height: 90px;
        border-radius: 10px;
        overflow: hidden;
        flex: 0 0 90px;
        border-radius: 10px;
        position: relative;
      }

      .boxSelectionSubject {
        position: absolute;
        bottom: 0px;
        right: 0px;
        background: #4F6FFF;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 30px;
        height: 25px;

        border-radius: 8px 0px 0px 0px;

        &:hover {
          cursor: pointer;
        }

        img {
          width: 18px;
          height: 18px;
        }
      }

      .imgsList {
        margin-left: 30px;
        padding-left: 30px;

        display: flex;
        border-left: 1px solid #DFDFDF;

        .img {
          display: flex;
          position: relative;
          width: 90px;
          flex: 0 0 90px;
          height: 90px;
          background: #ffffff;
          border-radius: 10px;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          margin-right: 12px;
          padding: 5px;
          position: relative;

          overflow: visible;
          border: 1px solid #DCDFE6;

          &:hover {
            cursor: pointer;
          }
        }

        /deep/.el-image {
          display: flex;
          width: 80px;
          height: 80px;
          flex: 0 0 80px;
          justify-content: center;
          align-items: center;

          .el-image__inner {
            max-width: 100%;
            /* 图片最大宽度为容器宽度 */
            max-height: 100%;
            /* 图片最大高度为容器高度 */
            object-fit: contain;
            /* 保持图片比例，完整显示 */
          }
        }

        .imgactive {
          border: 2px solid #1A73E8;

        }
      }
    }

    .query {
      color: $homePageSubjectColor;
    }

    .quantity {
      margin-left: auto;

      font {
        color: #ffa018;
      }
    }
  }

  /deep/.searchImg {
    border-radius: 10px;
    border: 1px solid rgba(#1e2997, 0.3);
    $imgSize: 50px;
    width: $imgSize;
    height: $imgSize;
    transition: 0.3s;
    text-align: center;

    i {
      font-size: $imgSize/4;
      line-height: $imgSize;
    }
  }

  .bottomCon {
    display: flex;
    height: 60px;
    align-items: center;

    .composeType {
      padding: 0 20px;
      width: 100%;
      height: 60px;
      background: #f0f0f0;
      border-radius: 10px;
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #000000;
      line-height: 24px;

      .title {
        margin-right: 80px;
        cursor: pointer;
      }

      >div {
        display: flex;
        align-items: center;
      }

      .salesVolume {
        margin-right: 80px;

        .el-select {
          width: 160px;
          height: 30px;
          border-radius: 6px;
        }

        /deep/.el-input__inner {
          width: 160px;
          height: 30px;
          border-radius: 6px;
          border: 1px solid rgba(#1e2997, 0.3);
        }

        /deep/.el-input__suffix {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .priceSection {
        display: flex;
        align-items: center;

        .fg {
          margin: 0 10px;
          width: 21px;
          height: 1px;
          background-color: black;
        }

        .inputBox {
          display: flex;
          align-items: center;
          width: 120px;
          height: 30px;
          border-radius: 6px;
          background-color: white;
          border: 1px solid rgba(#1e2997, 0.3);

          span {
            width: 10px;
            height: 20px;
            font-size: 14px;

            color: #000000;
            line-height: 20px;
            margin-left: 10px;
          }

          input {
            border: none;
            outline: none;
            width: 100%;
            padding: 0 3px;
          }
        }
      }
    }
  }
}
</style>
